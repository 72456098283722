import React, { useState, useRef, useEffect, useCallback } from 'react';
import { createPortal } from 'react-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import './css/LoginPage.css';

const Login = ({ isVisible, mode, toggleForm }) => {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        confirmPassword: '',
        email: ''
    });
    const [isLogin, setIsLogin] = useState(mode === 'login');
    const [usernameAvailable, setUsernameAvailable] = useState(true);
    const [emailAvailable, setEmailAvailable] = useState(true);
    const [recaptchaResponse, setRecaptchaResponse] = useState('');
    const [recaptchaWarning, setRecaptchaWarning] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [registrationSuccess, setRegistrationSuccess] = useState(false);
    const portalRoot = document.getElementById('portal-root');
    const containerRef = useRef();

    const { username, password, confirmPassword, email } = formData;


    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });

        if (e.target.name === 'password' || e.target.name === 'confirmPassword') {
            setPasswordError(''); // Reset password error state on change
        }

        if (e.target.name === 'username') {
            setUsernameAvailable(true);
        }
    
        if (e.target.name === 'email') {
            setEmailAvailable(true);
        }
    };

    const emailIsValid = email => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const checkUsernameAvailability = useCallback(async () => {
        if (!isLogin && username.trim() !== '') {
            try {
                console.log(`Checking availability for username: ${username}`);
                const response = await axios.get(`https://neuratrade.app/api/check-username?username=${username}`);
                setUsernameAvailable(response.data.available);
            } catch (error) {
                console.error('Error checking username availability:', error);
            }
        }
    }, [username, isLogin]);

    const checkEmailAvailability = useCallback(async () => {
        if (!isLogin && emailIsValid(email) && email.trim() !== '') {
            try {
                console.log(`Checking availability for email: ${email}`);
                const response = await axios.get(`https://neuratrade.app/api/check-email?email=${email}`);
                setEmailAvailable(response.data.available);
            } catch (error) {
                console.error('Error checking email availability:', error);
            }
        }
    }, [email, isLogin]);

    useEffect(() => {
        if (!isLogin) {
            const usernameInput = document.getElementById('username-signup');
            const emailInput = document.getElementById('email-signup');
            usernameInput.addEventListener('blur', checkUsernameAvailability);
            emailInput.addEventListener('blur', checkEmailAvailability);
            return () => {
                usernameInput.removeEventListener('blur', checkUsernameAvailability);
                emailInput.removeEventListener('blur', checkEmailAvailability);
            };
        }
    }, [username, email, isLogin, checkUsernameAvailability, checkEmailAvailability]);

    useEffect(() => {
        // Check if ReCAPTCHA has been completed
        if (recaptchaResponse !== '') {
            // Reset the warning state
            setRecaptchaWarning(false);
        }
    }, [recaptchaResponse]);

    const validatePassword = () => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return regex.test(password);
    };

    const onSubmit = async e => {
        e.preventDefault();

        if (!recaptchaResponse) {
            setRecaptchaWarning(true);
            return;
        }

        if (!isLogin && !emailIsValid(email)) {
            alert('Invalid email format');
            return;
        }

        if (!username || !password) {
            alert('Username and password are required');
            return;
        }
        
        if (!validatePassword()) {
            setPasswordError(
                <div>
                    <Typography variant="body2">
                        <strong>Password must meet the following criteria:</strong>
                    </Typography>
                    <ul>
                        <li>At least 8 characters long</li>
                        <li>Contain at least one uppercase letter</li>
                        <li>Contain at least one number</li>
                        <li>Contain at least one special character</li>
                    </ul>
                </div>
            );
            return;
        }

        if (!isLogin && password !== confirmPassword) {
            setPasswordError('Passwords do not match');
            return;
        }

        if (!usernameAvailable) {
            alert('Username is already taken');
            return;
        }

        if (!isLogin && !emailAvailable) {
            alert('Email is already taken');
            return;
        }
        try {
            let response;
            if (isLogin) {
                response = await axios.post('https://neuratrade.app/api/login', { username, password });
                if (response.data.success) {
                    console.log('Login successful');
                    // Redirect to dashboard route
                    // You may need to use React Router for this
                } else {
                    console.error('Login failed:', response.data.error);
                }
            } else {
                response = await axios.post('https://neuratrade.app/api/register', { ...formData, recaptchaResponse });
                if (response.data.success) {
                    console.log('Registration successful');
                    setRegistrationSuccess(true); // Show registration success message
                    // Toggle to login mode after successful registration
                    setIsLogin(true);
                } else {
                    console.error('Registration failed:', response.data.error);
                }
            }
        } catch (error) {
            console.error('An unexpected error occurred:', error);
        }
    };

    const toggleMode = () => {
        setUsernameAvailable(true);
        setEmailAvailable(true);
        setIsLogin(!isLogin);
        setRegistrationSuccess(false); // Hide registration success message when toggling modes
    };

    return createPortal(
        <Box
            ref={containerRef}
            className={`flipping-dialogue ${isVisible ? 'show' : ''}`}
            sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                p: 4,
                borderRadius: 2,
                boxShadow: 24,
                zIndex: 1201,
            }}
        >
            <IconButton
                aria-label="close"
                onClick={() => toggleForm(false)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            >
                <CloseIcon />
            </IconButton>

            <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
                {isLogin ? 'Welcome Back' : 'Create an Account'}
            </Typography>

            {registrationSuccess && (
                <Typography variant="body2" sx={{ color: 'green', mb: 2 }}>
                    Registration successful. Please log in.
                </Typography>
            )}

            {/* Button group */}
            <div className="button-group">
                <button
                    className={`group-login-button ${isLogin ? 'active' : ''}`}
                    onClick={() => setIsLogin(true)}
                >
                    Login
                </button>
                <button
                    className={`group-signup-button ${!isLogin ? 'active' : ''}`}
                    onClick={() => setIsLogin(false)}
                >
                    Sign Up
                </button>
            </div>

            <Box
                component="form"
                onSubmit={onSubmit}
                sx={{ width: '100%', mt: 1 }}
            >
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id={isLogin ? 'username-login' : 'username-signup'}
                    label="Username"
                    name="username"
                    autoComplete="username"
                    autoFocus
                    value={username}
                    onChange={onChange}
                />
                {!isLogin && !usernameAvailable && (
                    <Typography variant="body2" color="error">
                        This username is not available.
                    </Typography>
                )}
                {!isLogin && (
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email-signup"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        value={email}
                        onChange={onChange}
                    />
                )}
                {!isLogin && !emailAvailable && (
                    <Typography variant="body2" color="error">
                        This email is already in use.
                    </Typography>
                )}
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id={isLogin ? 'password-login' : 'password-signup'}
                    autoComplete={isLogin ? 'current-password' : 'new-password'}
                    value={password}
                    onChange={onChange}
                />
                {!isLogin && (
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="confirmPassword"
                        label="Confirm Password"
                        type="password"
                        id="confirm-password-signup"
                        autoComplete="new-password"
                        value={confirmPassword}
                        onChange={onChange}
                    />
                )}
                {!isLogin && passwordError && (
                    <Typography variant="body2" color="error">
                        {passwordError}
                    </Typography>
                )}

                <ReCAPTCHA
                    sitekey="6LcykZ4pAAAAAGOlMSi09Jo6vb8JQZPLbH420uPo"
                    onChange={token => setRecaptchaResponse(token)}
                    className="recaptcha"
                    theme="dark"
                />
                {recaptchaWarning && (
                    <Typography variant="body2" color="error">
                        Please complete the ReCAPTCHA.
                    </Typography>
                )}

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    {isLogin ? 'Login' : 'Sign Up'}
                </Button>

                <Typography variant="body2" sx={{ mt: 2 }}>
                    {isLogin ? "Don't have an account?" : "Already have an account?"}{' '}
                    <span className="login-link" onClick={toggleMode}>
                        {isLogin ? 'Sign Up' : 'Login'}
                    </span>
                </Typography>
            </Box>
        </Box>,
        portalRoot
    );
};

export default Login;
