import React from 'react';

const UserProfile = () => {
    return (
        <div>
            <h2>User Profile</h2>
            {/* Add user profile details here */}
        </div>
    );
}

export default UserProfile;
