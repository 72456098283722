import React, { useState } from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Login from './Login'; // Assuming you have this component
import './css/Navbar.css'; // Path to your CSS file

const Navbar = () => {
  const [isFormVisible, setFormVisible] = useState(false);
  const [formMode, setFormMode] = useState('login'); // 'login' or 'signup'

  const handleFormToggle = (mode) => {
    setFormMode(mode);
    setFormVisible(!isFormVisible || formMode !== mode);
  };

  const handleSignupToggle = () => {
    handleFormToggle('signup');
  };

  const handleLoginToggle = () => {
    handleFormToggle('login');
  };

  return (
    <AppBar position="fixed" id="custom-navbar" className='custombar'>
      <Toolbar>
        <Typography variant="h6" component="div" className="navbar-brand">
          <Link to="/" className="navbar-link">NeuraTrade</Link>
        </Typography>
        <div className="login-register">
          <Link to="#" className="login-button" onClick={handleLoginToggle}>Login</Link>
          <Link to="#" className="register-button" onClick={handleSignupToggle}>Sign Up</Link>
        </div>
      </Toolbar>
      {isFormVisible && <Login isVisible={isFormVisible} mode={formMode} toggleForm={setFormVisible} />}
    </AppBar>
  );
}

export default Navbar;
