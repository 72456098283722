import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import './css/Home.css'; // Make sure the path matches your structure


const Home = () => {
    const [expanded, setExpanded] = useState(null);

    const handleExpand = (index) => {
        setExpanded(prevIndex => prevIndex === index ? null : index);
    };

    const faqs = [
        {
            question: "What is Predictive Analytics?",
            answer: "Predictive analytics involves using historical data, statistical algorithms, and machine learning techniques to identify the likelihood of future outcomes based on historical data. In the context of crypto trading, predictive analytics helps anticipate market movements."
        },
        {
            question: "How does Automated Trading work?",
            answer: "Automated trading, also known as algorithmic trading, involves using computer programs to execute trades automatically based on predefined criteria. In the context of crypto trading, automated trading strategies can be designed to take advantage of market opportunities without manual intervention."
        },
        {
            question: "What is Risk Management in crypto trading?",
            answer: "Risk management in crypto trading involves identifying, assessing, and prioritizing risks associated with trading cryptocurrencies and implementing strategies to mitigate or minimize those risks. AI-driven risk management utilizes advanced algorithms to analyze market data and make informed decisions to reduce risk exposure."
        }
    ];

    return (
        <div className="container">
            <section id="hero" className="hero">
                <div className="hero-content">
                    <div className="image-container">
                        
                    </div>
                    <div className="text-container">
                        <h1 className="heading">Transforming Crypto Trading with AI</h1>
                        <p className="subheading">Experience the power of artificial intelligence in crypto trading</p>
                        <button className="cta-button">Get Started</button>
                    </div>
                </div>
            </section>

            <section id="features" className="features">
                <h2 className="section-heading">Key Features</h2>
                <div className="feature-grid">
                    <div className="feature-card">
                        <h3 className="feature-title">Predictive Analytics</h3>
                        <p className="feature-description">Anticipate market movements with advanced AI algorithms</p>
                    </div>
                    <div className="feature-card">
                        <h3 className="feature-title">Automated Trading</h3>
                        <p className="feature-description">Execute trades automatically based on AI insights</p>
                    </div>
                    <div className="feature-card">
                        <h3 className="feature-title">Risk Management</h3>
                        <p className="feature-description">Minimize risk exposure with AI-driven risk management</p>
                    </div>
                </div>
            </section>

            <section id="benefits" className="benefits">
                <h2 className="section-heading">Benefits</h2>
                <div className="benefit-grid">
                    <div className="benefit-card">
                        <h3 className="benefit-title">Increased Profits</h3>
                        <p className="benefit-description">Maximize your profits with intelligent trading decisions</p>
                    </div>
                    <div className="benefit-card">
                        <h3 className="benefit-title">Time Saving</h3>
                        <p className="benefit-description">Save time with automated trading strategies</p>
                    </div>
                    <div className="benefit-card">
                        <h3 className="benefit-title">Reduced Risk</h3>
                        <p className="benefit-description">Reduce the risk of losses with AI-driven risk management</p>
                    </div>
                </div>
            </section>

            <section id="faq" className="faq">
                <h2 className="section-heading">FAQ</h2>
                <div className="faq-grid">
                    {faqs.map((faq, index) => (
                        <div className="faq-card" key={index}>
                            <div className="faq-question" onClick={() => handleExpand(index)}>
                                <h3 className="faq-title">{faq.question}</h3>
                                <FontAwesomeIcon icon={expanded === index ? faChevronUp : faChevronDown} className="faq-icon" />
                            </div>
                            {expanded === index && (
                                <div className="faq-answer">
                                    <p>{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </section>

            <section id="pricing" className="pricing">
                <h2 className="pricing-heading">Pricing</h2>
                <div className="pricing-grid">
                    <div className="pricing-card">
                        <h3 className="pricing-title">Basic Plan</h3>
                        <p className="pricing-price">$9.99/month</p>
                        <ul className="pricing-features">
                            <li>Access to AI-powered trading algorithms</li>
                            <li>Limited number of trades per month</li>
                            <li>Basic customer support</li>
                        </ul>
                        <button className="pricing-button">Subscribe</button>
                    </div>
                    <div className="pricing-card">
                        <h3 className="pricing-title">Pro Plan</h3>
                        <p className="pricing-price">$19.99/month</p>
                        <ul className="pricing-features">
                            <li>Unlimited access to AI-powered trading algorithms</li>
                            <li>Premium customer support</li>
                            <li>Advanced risk management features</li>
                        </ul>
                        <button className="pricing-button">Subscribe</button>
                    </div>
                </div>
            </section>

            <footer className="footer">
                <p className="footer-text">Ready to revolutionize your trading?</p>
                <button className="footer-cta-button">Get Started Now</button>
            </footer>
        </div>
    );
}

export default Home;